import { ErrorTemplate } from '@features/errors/ErrorTemplate';
import { AppLayout } from '@features/ui/components/layout/AppLayout';
import { NextPageWithLayout } from '@features/ui/types/NextPageWithLayout';
import staticProps from '@services/staticProps';
import { ReactElement } from 'react';

const PageNotFound: NextPageWithLayout = () => <ErrorTemplate code="404" />;

PageNotFound.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>;
};

export default PageNotFound;

export const getStaticProps = staticProps;
