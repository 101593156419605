import { Card, ContentHeader } from '@features/ui/components';
import { useTranslation } from 'next-i18next';

type ErrorTemplateProps = {
  children?: React.ReactNode;

  // Add codes here when you add the corresponding translations, not before!
  code?: '404' | 'default' | 'down';
  title?: string;
  upperTitle?: string;
};

export const ErrorTemplate = ({ children, title, upperTitle, code }: ErrorTemplateProps): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <>
      <ContentHeader
        title={title || t(`pages.errors.${code ?? 'default'}.title`)}
        upperTitle={upperTitle || t(`pages.errors.${code ?? 'default'}.upperTitle`)}
      />

      {children && <Card>{children}</Card>}
    </>
  );
};
